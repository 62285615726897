<template>
  <div>
    <b-button
      :to="backRoute"
      class="mr-4 dark-grey-buttons"
    >
      <b-icon
        icon="arrow-left-circle-fill"
        aria-hidden="true"
        class="top--2"
      ></b-icon>
    </b-button>
  </div>
</template>

<script>

import GetAllItemPathOfTeamInGroup from '@/graphQlQueries/queries/getAllItemPathOfTeamInGroup';
import GetIdBySlugPath from '@/graphQlQueries/queries/getIdBySlugPath';
import GetLastVisitedUrlFromCache from '@/graphQlQueries/queries/getLastVisitedUrlFromCache';

export default {
  name: 'HeaderActionBackButton',
  props: {
    pageType: { type: String },
    userObject: { type: Object },
    groupId: { type: Number },
  },
  data: () => ({
    lastVisitedUrl: '/',
    currentItemPath: null,
    teamsItemsPaths: null,
    settingPages: [
      'settings',
      'page-settings',
      'folder-settings',
      'general-settings',
      'project-settings',
    ],
  }),
  apollo: {
    lastVisitedUrl: {
      query: GetLastVisitedUrlFromCache,
      update(data) {
        return data.lastVisitedUrl.url;
      },
    },
    currentItemPath: {
      query: GetIdBySlugPath,
      variables() {
        return {
          groupId: this.groupId,
          path: `/${this.$route.params.parametersOfUrl}`,
        };
      },
      update(data) {
        return (data.item_lookup[0]?.item_path)
          ? data.item_lookup[0].item_path
          : null;
      },
      skip() {
        return !this.groupId || this.$route.path === '/' || this.userObject.isGroupUser;
      },
      fetchPolicy: 'network-only',
    },
    teamsItemsPaths: {
      query: GetAllItemPathOfTeamInGroup,
      variables() {
        return {
          teamIdArray: this.userObject.teamIdsOfUser,
          groupId: this.groupId,
        };
      },
      update(data) {
        const teamItemPath = [];
        data.teams_items.forEach((item) => {
          if (item.folder) {
            teamItemPath.push(item.folder.item_path);
          }
          if (item.project) {
            teamItemPath.push(item.project.item_path);
          }
        });
        return teamItemPath;
      },
      skip() {
        return !this.groupId || this.$route.path === '/' || this.userObject.isGroupUser;
      },
    },
  },
  computed: {
    backRoute() {
      let newRoute = null;
      // check if a user is team user and has to over jump elements
      if (!this.userObject.isGroupUser && this.currentItemPath && this.teamsItemsPaths) {
        if (
          this.teamsItemsPaths.includes(this.currentItemPath)
          && !this.settingPages.includes(this.pageType)
        ) {
          return '/';
        }
      }
      // we use this after resetting the slug of the settings page
      let goBackInTheItemsTree = false;
      this.settingPages.forEach((settingPath) => {
        if (this.lastVisitedUrl && this.lastVisitedUrl.endsWith(settingPath)) {
          goBackInTheItemsTree = true;
        }
      });
      // if a user is on the general settings page always go back to the dashboard
      if (this.pageType === 'general-settings') {
        return '/';
      }
      // if the pageType contains settings go back to the last visited url
      if (
        (this.settingPages.includes(this.pageType) && this.lastVisitedUrl)
      ) {
        if (goBackInTheItemsTree) {
          newRoute = this.$route.fullPath.substring(0, this.$route.fullPath.lastIndexOf('/'));
        } else {
          newRoute = this.lastVisitedUrl;
        }
      } else {
        const elementsOfCurrentRoute = (this.$route.params.parametersOfUrl)
          ? this.$route.params.parametersOfUrl.split('/')
          : null;
        if (elementsOfCurrentRoute?.length > 1) {
          elementsOfCurrentRoute.pop();
          newRoute = `/${elementsOfCurrentRoute.join('/')}`;
        } else {
          newRoute = '/';
        }
      }
      return newRoute;
    },
  },
};
</script>
